#infoTitle4 {
    font-size: 1.5em;
    width: 90vw;
  }

  table{
    max-width: 80vw;
  }
  .radio {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: "#D9D9D9";
    border: 2px solid white;
    position: relative;
  }

  .radio::selection {
    background-color: #000;
  }
  
  .option.selected .radio {
    border-color: #2f5656;
    background-color: #3D6868;
  }