.App {
  text-align: left;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #F5F5F5;
  max-width: 100%;
}

.makeItCenter {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.backgroundColorHighlight{
  background-color: #3d6868;
  color: white
}

.after-login-container{
  padding-left: 2vw;
  padding-right: 2vw;
}