.welcome-screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #66b0b0;
  font-family: "Arial", sans-serif;
  transition: opacity 0.5s ease-in-out;
}

.welcome-screen-text {
  color: white;
}

.welcome-screen #infoText1 {
  margin-bottom: 2rem;
  margin-left:8vw;
  font-size: 1.4em;
  top:10vh;
  max-width:50vw;
  position:absolute;
}

.welcome-screen #infoText2 {
  margin-bottom: 2rem;
  margin-left:8vw;
  font-size: 0.8em;
  bottom:20vh;
  max-width:70vw;
  position:absolute;
}


.welcome-screen .infoTextWelcome2{
    font-size: 1.6em;
}
.infoText{
  font-size: 1em;
}

.illustration {
  max-width: 100%;
  height: 70vh;
  margin: 0 auto;
  display: block;
  position: absolute;
  bottom: 16vh;
  right:0vw;
  text-align: right;
  transform: scale(1.2); 
  transform-origin: right bottom; 
}