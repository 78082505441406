.radio {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid gray;
  }
  
  .radio.selected {
    background-color: blue;
  }
  
  .radio.dark {
    background-color: rgb(192, 187, 187);
  }
  
  .radio.selected.dark {
    background-color: #3d6868;
  }
  