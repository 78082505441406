  .dot {
    width: 6vw;
    height: 10px;
    background-color: #7fc4c4;
    border-radius: 5px;
    margin-right: 5px;
    transition: all 0.3s ease;
  }

  .dot.active {
    width: 14vw;
    background-color: #3d6868;
  }

  .dot:last-child {
    margin-right: 0;
  }

  .progressDots {
    z-index: 9000;
    width: 100%;
    position: fixed;
    bottom: 10vw;
  }